import styles from './GamesSection.module.scss';

import GameCard from '../GameCard/GameCard';
import { games } from '../../lib/games';

const GamesSection = () => {
    return (
        <section id="games" className={styles.gamesContainer}>
            <h2>Our games</h2>
            <div className={styles.gameCardsContainer}>
                {games.map((game, i) => (
                    <GameCard key={i} game={game} />
                ))}
            </div>
        </section>
    );
};

export default GamesSection;
