import styles from './GiftSection.module.scss';

import Button from '../Button/Button';

const GiftSection = ({ handleGiftClaim, data }) => {
    return (
        <div className={styles.giftInfo}>
            <h3>Free daily gift</h3>
            <img src={data.chestImage} alt="Daily Gift" />
            <p className={styles.chestName}>{data.chestName}</p>
            <Button onClick={handleGiftClaim} name="Claim" className="orange"></Button>
        </div>
    );
};

export default GiftSection;
