import { useEffect, useCallback, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSearchParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';

import AboutSection from './components/AboutSection/AboutSection';
import BannerShop from './components/BannerShop/BannerShop';
import Footer from './components/Footer/Footer';
import GamesSection from './components/GamesSection/GamesSection';
import Header from './components/Header/Header';
import GiftSection from './components/GiftSection/GiftSection';
import Login from './components/Login/Login';
import Contacts from './components/Contacts/Contacts';
import Loader from './components/Loader/Loader';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import api from './lib/api'

import {
    DEFAULT_SHOP_DISCOUNT,
    LOCAL_STORAGE_KEY,
    ERROR_MESSAGE_DEFAULT
} from './lib/helpers';

function App() {
    const [state, setState] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();

    const fetchUserData = useCallback(async (pidCode) => {

        setIsLoading(true);

        let response = {};
        try {
            response = await api.send({
                url: '/user/login',
                data: {
                    pidCode: pidCode
                }
            });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            handleLogout();
            toast.error(ERROR_MESSAGE_DEFAULT);
            console.log(err);
        }

        return response;
    }, []);

    const handleLogin = useCallback(async (pidCode) => {
        if (pidCode) {
            let response = await fetchUserData(pidCode);
            if (response && response.success) {
                setState(response.data);
                localStorage.setItem(LOCAL_STORAGE_KEY, pidCode);
            } else {
                toast.error(response.error || ERROR_MESSAGE_DEFAULT);
                localStorage.removeItem(LOCAL_STORAGE_KEY);
            }
        }
    }, [fetchUserData]);

    const handleGiftClaim = async () => {

        setIsLoading(true);

        try {
            let response = await api.send({
                url: '/user/gift/claim',
                data: {
                    pid: state.user?.pid,
                    id: state.data.giftId
                }
            });
            setIsLoading(false);
            if (response && response.success) {
                toast.success('Success!');
                setState({
                    ...state,
                    user: { ...state.user, hasChestToClaim: false },
                });
            } else {
                toast.error(response.error || ERROR_MESSAGE_DEFAULT);
            }
        } catch (err) {
            setIsLoading(false);
            toast.error(ERROR_MESSAGE_DEFAULT);
            console.log(err);
        }
    };

    const handleLogout = () => {
        setState({});
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    useEffect(() => {

        let pidCodeParam = searchParams.get('pid')
        if (pidCodeParam) {
            handleLogin(pidCodeParam);
            return;
        }

        let pidCode = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (pidCode) {
            handleLogin(pidCode);
        } else {
            setIsLoading(false);
        }
    }, [handleLogin, searchParams]);

    return (
        <div className="app">
            <ScrollToTop />
            <ToastContainer
                theme="light"
                autoClose={3000}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
            />
            {isLoading && <Loader />}
            <Header userData={state.user} handleLogout={handleLogout} />
            <main>
                {state.user ? (
                    state.user.hasChestToClaim ? (
                        <GiftSection
                            handleGiftClaim={handleGiftClaim}
                            data={state.data}
                        />
                    ) : null
                ) : (
                    isLoading ? null : <Login handleLogin={handleLogin} />
                )}
                <BannerShop
                    percentage={
                        state.user
                            ? state.data?.shopPercentage
                            : DEFAULT_SHOP_DISCOUNT
                    }
                    pid={state.user?.pidCode}
                />
                <AboutSection />
                <GamesSection />
                <Contacts />
            </main>
            <Footer />
        </div>
    );
}

export default App;
