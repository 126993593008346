import { useState } from 'react';

import styles from './Login.module.scss';

import Button from '../Button/Button';

const Login = ({ handleLogin }) => {
    const [pid, setPid] = useState('');

    return (
        <div className={styles.loginContainer}>
            <p>Login to claim your FREE gift!</p>
            <div>
                <input
                    type="text"
                    placeholder="Player ID"
                    value={pid}
                    onChange={(e) => setPid(e.target.value.trim())}
                />
            </div>
            <Button onClick={() => handleLogin(pid)} name="Login"></Button>
        </div>
    );
};

export default Login;
