import styles from './BannerShop.module.scss';
import imgBanner from '../../assets/banner-shop.jpg';
import Button from '../Button/Button';


const BannerShop = ({ percentage, pid }) => {
    return (
        <div>
            <a
                href={`https://store.godzilab-games.com/store/${
                    pid ? `?pid=${pid}` : ''
                }`}
                target="_blank"
                rel="noreferrer"
                className={styles.container}
            >
                <img src={imgBanner} alt="Banner of web shop" />
                <div className={styles.discountWrapper}>
                    <div
                        className={`${styles.discountBgr} ${
                            pid ? styles.animated : ''
                        }`}
                    ></div>
                    <div className={styles.discountContainer}>
                        <span className={styles.discount}>
                            {percentage}%
                            <div className={styles.discountLabel}>discount</div>
                        </span>
                    </div>
                </div>
            </a>
            <Button
                name="Get Discount"
                href={`https://store.godzilab-games.com/store/${
                    pid ? `?pid=${pid}` : ''
                }`}
                target="_blank"
                className="orange"
            ></Button>
        </div>
    );
};

export default BannerShop;
