import styles from './UserInfo.module.scss';

import Button from '../Button/Button';

const UserInfo = ({ user, handleLogout }) => {
    return (
        <div className={styles.avatarInfo}>
            <div>
                <img
                    className={styles.userAvatar}
                    src={user.avatar}
                    alt="User Avatar"
                />
                <img
                    className={styles.userLvl}
                    src={user.lvl}
                    alt="User Level"
                />
            </div>
            <div className={styles.userName}>{user.name}</div>
            <Button onClick={handleLogout} name="Logout"></Button>
        </div>
    );
};

export default UserInfo;
