import imgCTA from '../assets/games/cta.jpg';
import imgIO from '../assets/games/io.jpg';
import imgSiege from '../assets/games/siege.jpg';
import imgBaloot from '../assets/games/baloot.jpg';
import imgEmperors from '../assets/games/emperors.jpg';
import imgHero from '../assets/games/hero.jpg';
import imgKingdom from '../assets/games/kingdom.png';
import imgSOW from '../assets/games/sow.jpg';

export const games = [
    {
        imageUrl: imgCTA,
        title: 'Crush Them All',
        releaseDate: 'May 2017',
        description:
            'March your way through the evil lands, crush the gigantic bosses and free the princess in the epic IDLE role-playing game, Crush Them All. ' +
            'Tap with one finger, collect and upgrade hundreds of heroes, find powerful artifacts and progress in this never-ending journey even while taking a break!',
        buttons: [
            {
                type: 'mixed',
                url: 'https://cta.onelink.me/Gflp/web',
            },
        ],
    },
    {
        imageUrl: imgIO,
        title: 'Imperia Online',
        releaseDate: 'August 2005',
        description:
            'Imperia Online brings you into a world, which exists in the times before the age of gunpowder. ' +
            'It is the world of the sharp blade, long bow and war horse. A time of political intrigues, massive medieval battles, ' +
            'fortresses sieged by catapults and trebuchets, heavy knights wiping out entire armies, civillian population pillages ' +
            'and thousands of soldiers falling in a single battles. A times of powerful alliances, fighting eachother for total domination.',
        buttons: [
            {
                type: 'browser',
                url: 'http://www.imperiaonline.com/',
            },
            {
                type: 'google',
                url: 'https://play.google.com/store/apps/details?id=org.imperiaonline.android.v6',
            },
            {
                type: 'apple',
                url: 'https://itunes.apple.com/us/app/imperiaonline-great-people/id733757567',
            },
        ],
    },
    {
        imageUrl: imgSiege,
        title: 'SIEGE: WORLD WAR II',
        releaseDate: 'September 2018',
        description:
            'SIEGE: WWII is developed by Simutronics and published by Imperia Online JSC since 2020. ' +
            'Clash with your opponents in head-to-head duels in this PvP game against real players from all over the world. ' +
            'Make strategic decisions, lead military operations and withstand the tough competition to top the seasonal leaderboards. ' +
            'Think you have what it takes to be a World War II general? Put your decision-making skills to the test in SIEGE: World War II.',
        buttons: [
            {
                type: 'google',
                url: 'https://play.google.com/store/apps/details?id=com.simutronics.b17',
            },
            {
                type: 'apple',
                url: 'https://apps.apple.com/us/app/siege-world-war-ii/id1373496888',
            },
        ],
    },
    {
        imageUrl: imgBaloot,
        title: 'BALOOT ROYALE',
        releaseDate: 'May 2018',
        description:
            "Baloot Royale is developed by Imperia Online JSC based on a concept by Babil Games which are also the game's publisher. " +
            'The most popular card game in the Middle East, especially in the Gulf. Baloot Royale is a four players card game played ' +
            'in teams of two, the first team to reach 152 points wins. Have fun and compete with players from all over the world. ' +
            'Win Real Prizes by competing in tournaments and be the champion by reaching the highest level on the ranking list.',
        buttons: [
            {
                type: 'google',
                url: 'https://play.google.com/store/apps/details?id=org.imperiaonline.balootmasters&hl=bg&gl=US',
            },
            {
                type: 'apple',
                url: 'https://apps.apple.com/us/app/baloot-royale-%D8%A8%D9%84%D9%88%D8%AA-%D8%B1%D9%88%D9%8A%D8%A7%D9%84/id1407214532',
            },
        ],
    },
    {
        imageUrl: imgEmperors,
        title: 'GAME OF EMPERORS',
        releaseDate: 'February 2016',
        description:
            'Step into a Medieval world and become the mighty ruler of your empire! Build and develop your cities, ' +
            'strengthen your dynasty, amass your armies and lead them to battle. Form or break alliances with other ' +
            'players to gain a strategic advantage and conquer the realm. Enjoy a massive, deep, and rewarding MMORTS ' +
            'game with thousands of active players. Gather with old friends or make new ones as you combine your strength ' +
            'to form an unbreakable alliances.',
        buttons: [
            {
                type: 'browser',
                url: 'http://www.gameofemperors.com/',
            },
        ],
    },
    {
        imageUrl: imgHero,
        title: 'IMPERIAL HERO',
        releaseDate: 'February 2015',
        description:
            'The wondrous and amazing lands of Ayarr Empire await all eminent heroes who wish to achieve glory, immortality and become legends. ' +
            'Will they be the heroes who bring light into the dark dungeons? ' +
            'Or will they be master crafters, who create powerful items, selling them to other heroes? ' +
            'Will they be Leaders of a Hero Guild, who will conquer all of the No Man’s Lands? Imperial Hero is the game, ' +
            'which gives the players the choice to be any of these. With the single purpose of becoming the Ultimate Hero.',
        buttons: [
            {
                type: 'browser',
                url: 'https://www2.imperialhero.org/web/public/',
            },
        ],
    },
    {
        imageUrl: imgKingdom,
        title: 'KINGDOM OF GLORY',
        releaseDate: 'December 2021',
        description:
            'Kingdom of Glory is the latest strategy game developed by us and published by MBC Group, the largest media company in the MENA region. ' +
            'Live the Arabian glory, build a powerful kingdom, conquer your enemies, form alliances, friendships, and rule the world with your alliance! ' +
            'It is time to enjoy a real military strategy game that takes place in a pure Arabic frame with its graphics, designs, characters, leaders, units, and story. ' +
            'To form new friendships and foster old ones, and apply unique strategies to achieve victory and crush your enemies.',
        buttons: [
            {
                type: 'google',
                url: 'https://play.google.com/store/apps/details?id=net.mbc.pocketkingdom&hl=bg&gl=US',
            },
            {
                type: 'apple',
                url: 'https://apps.apple.com/eg/app/kingdom-of-glory/id1565812222',
            },
        ],
    },
    {
        imageUrl: imgSOW,
        title: 'SEASONS OF WAR',
        releaseDate: 'January 2015',
        description:
            '‘Seasons of War’ allows the player to become the Governor of a distant Imperial city and grow their holding through wise rule.' +
            'The Android game takes the turn-based strategy genre to a whole new level with a vast variety of features: Economical ' +
            'development (including agriculture, education, healthcare, and trade); PvP battles; Fights against savage Barbarians and ' +
            'conquering of their castles; Civil and combat efficiency improvement through inventions, and much, much more! ‘Seasons of War’ ' +
            'presents a challenge to every player due to months and seasons having a significant effect on agriculture, trade, migration, healthcare, etc. ',
        buttons: [
            {
                type: 'google',
                url: 'https://play.google.com/store/apps/details?id=org.imperiaonline.android.seasons',
            },
        ],
    },
];
