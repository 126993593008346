import styles from './AboutSection.module.scss';

const AboutSection = () => {
    return (
        <section id="about">
            <div className={styles.aboutContainer}>
                <p className={styles.slogan}>WE LOVE GAMES</p>
                <p className={styles.description}>
                    Imperia Online JSC is one of the biggest game production
                    companies in Southeastern Europe, part of Stillfront Group,
                    and popular with 28 released games. The company's flagship title is Imperia Online - a medieval MMO
                    strategy game, available on mobile, browsers, social
                    networks and Steam. In June 2021 Godzi Lab has been acquired
                    by Stillfront Group and its Live Operations assigned to
                    Imperia Online. To learn more about Imperia Online, visit{' '}
                    <a href="https://imperiaonline.bg" target="_blank" rel="noreferrer">
                        https://imperiaonline.bg
                    </a>
                </p>
            </div>
            <div className={styles.imageContainer}></div>
        </section>
    );
};

export default AboutSection;
