import styles from './Header.module.scss';

import UserInfo from '../UserInfo/UserInfo';

const Header = ({ userData, handleLogout }) => {
    return (
        <header className={styles.header}>
            <a href="/" target="_self" className={styles.siteName}>
                Crush Them All
            </a>
            <hr />
            <nav>
                <ul>
                    <li>
                        <a href="#about">About</a>
                    </li>
                    <li>
                        <a href="#games">Other Games</a>
                    </li>
                    <li>
                        <a href="#contacts">Contacts</a>
                    </li>
                </ul>
            </nav>
            {userData && userData.pid ? (
                <UserInfo user={userData} handleLogout={handleLogout} />
            ) : null}
        </header>
    );
};

export default Header;
