import styles from './Button.module.scss';

const Button = ({ href, onClick, target, name, className }) => {
    if (href) {
        return (
            <a className={`${className || ''} ${styles.button}`} href={href} target={target}>
                {name}
            </a>
        );
    }

    if (onClick) {
        return (
            <button className={`${className || ''} ${styles.button}`} onClick={onClick}>
                {name}
            </button>
        );
    }
};

export default Button;
