import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faDiscord, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import logo from '../../assets/logo_stillfront_black.png';

import styles from './Contacts.module.scss';

const Contacts = () => {
    return (
        <>
            <section id="contacts" className={styles.contacts}>
                <p>
                    Don’t hesitate to contact us for more information about our
                    awesome games at{' '}
                    <a href="mailto:support@imperialsupport.org">
                        support@imperialsupport.org
                    </a>
                </p>
                <a
                    href="https://www.facebook.com/CrushThemAllGame"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                    href="https://discord.com/channels/243140817953226752/313906450814730240"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faDiscord} />
                </a>
                <a
                    href="https://www.instagram.com/crushthemall_io/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                    href="https://www.tiktok.com/@crushthemall_io"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faTiktok} />
                </a>
                <br />
                <a
                    href="https://gzidlerpg.appspot.com/store/privacy"
                    target="_blank"
                    rel="noreferrer"
                    className={styles['footer-link']}
                    style={{ marginLeft: '40px' }}
                >
                Privacy policy
                </a>
                <a
                    href="https://www.stillfront.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={styles['footer-link']}
                >
                    <img src={logo} className={styles.logo} alt="logo" />
                </a>
                <a
                    href="https://gzidlerpg.appspot.com/store/tos"
                    target="_blank"
                    rel="noreferrer"
                    className={styles['footer-link']}
                >
                Terms & conditions
                </a>
            </section>
        </>
    );
};

export default Contacts;
