import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

import styles from './ScrollToTop.module.scss';

const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    return (
        <div className={styles.scrollToTopContainer}>
            <FontAwesomeIcon
                icon={faAngleUp}
                className={`${showTopBtn ? styles.fadeIn : styles.fadeOut} ${
                    styles.icon
                }`}
                onClick={goToTop}
            />
        </div>
    );
};
export default ScrollToTop;
