import styles from './GameCard.module.scss';

import Button from '../Button/Button';

const BUTTON_TO_TEXT = {
    browser: 'View in Browser',
    google: 'Google Play',
    apple: 'App Store',
    mixed: 'Download iOS / Android',
};

const GameCard = ({ game }) => {
    return (
        <div className={styles.container}>
            <div
                className={styles.image}
                style={{
                    backgroundImage: `url(${game.imageUrl})`,
                }}
            ></div>
            <h3 className={styles.title}>{game.title}</h3>
            <p className={styles.releaseDate}>{game.releaseDate}</p>
            <p className={styles.description}>{game.description}</p>
            <div className={styles.buttons}>
                {game.buttons.map((btn, i) => (
                    <Button
                        key={`${i}-${btn.type}`}
                        href={btn.url}
                        target="_blank"
                        name={BUTTON_TO_TEXT[btn.type]}
                    ></Button>
                ))}
            </div>
        </div>
    );
};

export default GameCard;
