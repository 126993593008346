
const API_URL = ({
    'crush-them-all.com': 'https://gzidlerpg.appspot.com',
    'site.gzidlerpg.appspot.com': 'https://gzidlerpg.appspot.com',
    'site.dev-gzidlerpg.appspot.com': 'http://18804.dev-gzidlerpg.appspot.com',
    'localhost': 'http://192.168.16.143:8080'
})[window.location.hostname] || 'https://gzidlerpg.appspot.com';

const api = {
    send: async function send(options) {

        if (options.url && options.url[0] === '/') {
            options.url = options.url.slice(1);
        }

        const url = `${API_URL}/site/api/${options.url}`;
        const params = {
            method: 'POST'
        };

        if (options.data) {
            let data = new FormData();
            for (let key in options.data) {
                if (options.data.hasOwnProperty(key)) {
                    data.set(key, options.data[key]);
                }
            }
            // body data type must match "Content-Type" header
            params.body = data;
        }

        let response = await fetch(url, params);
        let result = await response.json();
        return result;
    }
}

export default api
